
// DO NOT EDIT. This is a generated file.
// tslint:disable

export const BUILD_INFO = {
  "name": "rivoluzionedimagrante-v3ng",
  "version": "3.0.31",
  "date": "2024-07-26T07:32:04.668Z",
  "branch": null,
  "rev": "37b7361db0",
  "tag": null,
  "commitsSinceLastTag": null,
  "authorDate": null,
  "buildNumber": null
};
